import firebase from 'firebase/app'
import 'firebase/firebase-firestore'
import 'firebase/firebase-auth'
import 'firebase/storage'
var firebaseConfig = {
  apiKey: "AIzaSyBe_HBw9qD19gZQLwxXudUbg-UW3MA0LQE",
  authDomain: "numanchi.firebaseapp.com",
  projectId: "numanchi",
  storageBucket: "numanchi.appspot.com",
  messagingSenderId: "343560387306",
  appId: "1:343560387306:web:87f28dbdb955381bdff593",
  measurementId: "G-9LXQ7BHE8W"
};
  // Initialize Firebase
const fb = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
export { fb, db }