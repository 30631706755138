/* eslint-disable no-unused-vars */
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false


Vue.mixin({
  data: () => ({
    menus: [
      { title: 'Home', icon: 'mdi-home', to: '/' },  
      { title: 'About', icon: 'mdi-account', to: '/about' }, 
      { title: 'services', icon: 'mdi-cog', to: '/services' },
      { title: 'products', icon: 'mdi-package-variant-closed', to: '/products' },
      { title: 'gallery', icon: 'mdi-image', to: '/gallery' },
      { title: 'Contact Us', icon: 'mdi-account-box', to: '/contact' },
      { title: 'Career', icon: 'mdi-handshake', to: '/career' },
    ]
  })
})

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title
  else if(previousNearestWithMeta) document.title = previousNearestWithMeta.meta.title 
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
  if(!nearestWithMeta) return next();
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');
    Object.keys(tagDef).forEach(key => { tag.setAttribute(key, tagDef[key]); });
    tag.setAttribute('data-vue-router-controlled', '');
    return tag;
  }).forEach(tag => document.head.appendChild(tag));
  next();
});

router.beforeEach((to, from, next) => {
  store.commit('SET_OVERLAY', true)
  if (to.name) store.commit('SET_OVERLAY', false)
  next()
})

import AOS from 'aos'
import 'aos/dist/aos.css'

new Vue({
  created () {
    store.dispatch('setImages')
    store.dispatch('setJobs')
    AOS.init()
  },
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
