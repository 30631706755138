<template>
  <v-footer color="white" class="py-6 py-sm-8 py-md-10 py-lg-12" padless>
    <v-container class="footer">
      <div class="company">
        <div class="d-flex align-center" style="grid-gap: 6px;">
          <v-img max-width="75" lazy-src="../assets/logo.png" src="../assets/logo.png" alt="Numanchi Logo"></v-img>
          <div class="d-flex flex-column" style="line-height: 1; grid-gap: 4px; letter-spacing: .5px;">
            <div class="font-weight-bold primary--text logo__text" v-text="'NUMANCHI'" />
            <div class="para" v-text="'Next gen is Here'" />
          </div>
        </div>   
        <div class="socials mt-3">
          <span class="para font-weight-bold black--text">Follow Us : </span>  
          <v-btn icon height="30" width="30" v-for="(social, s) in socials" :key="s" :color="social.color" target="_blank" :href="social.to">
            <v-icon size="16" v-text="social.icon" />
          </v-btn>  
        </div>
      </div>
      <div class="footer__links">
        <div class="links">
          <div class="link__title">Information</div>
          <div class="para" v-for="(inf, i) in info" :key="i">{{ inf }}</div>
        </div>
        <div class="links">
          <div class="link__title">Helpful Links</div>
          <div class="para" v-for="(link, l) in links" :key="l">{{ link }}</div>
        </div>
        <div class="links">
          <div class="link__title">Our Services</div>
          <div class="para" v-for="(service, s) in services" :key="s">{{ service }}</div>
        </div>
        <div class="links">
          <div class="link__title">Contact</div>
          <div class="para" v-for="(con, c) in contact" :key="c">{{ con }}</div>
        </div>  
      </div>
    </v-container>  
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    info: ['Home', 'About', 'Courses', 'Blog'],
    links: ['Student', 'Business', 'Instructor'],
    services: ['Design', 'Study', 'Business', 'UI/UX'],
    contact: ['24th street, downtown, New York', 'info@e-learn.com', '(545) 555-0214'],
    socials: [
      { icon: 'mdi-facebook', to: 'https://www.facebook.com', color: '#3b5998' },
      { icon: 'mdi-twitter', to: 'https://www.twitter.com', color: '#00acee'  },
      { icon: 'mdi-linkedin', to: 'https://www.linkedin.com', color: '#0e76a8' },
      { icon: 'mdi-instagram', to: 'https://www.instagram.com', color: '#8a3ab9' }
    ]
  })  
}
</script>

<style scoped lang="scss">
.footer {
  display: grid;
  grid-template-columns: 280px calc(100% - 330px);
  grid-gap: 50px;  
  .company {
    .logo__text {
      font-size: 20px;
      background: #21a5f7;
      background: -webkit-linear-gradient(to right, #21a5f7 0%, #b42cd6 100%);
      background: -moz-linear-gradient(to right, #21a5f7 0%, #b42cd6 100%);
      background: linear-gradient(to right, #21a5f7 0%, #b42cd6 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .footer__links {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));  
    .links {
      .para { 
        margin-bottom: 8px; 
        &:last-child { margin-bottom: 0 !important; }
      }
      .link__title {
        font-weight: bold;
        margin-bottom: 16px;  
        letter-spacing: .5px;
        font-size: 17px;
        line-height: 1.3;
      }
    }
  }
  .para {
    color: grey; 
    font-size: 13px; 
    line-height: 1.3;
  }
  @media (max-width: 960px) {
    grid-gap: 40px;  
    .footer__links { grid-template-columns: repeat(auto-fit, minmax(50%, 1fr)); }  
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr)); grid-gap: 30px; 
    .footer__links { row-gap: 20px; }
  }
}
</style>