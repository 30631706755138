import Vue from 'vue'
import Vuex from 'vuex'
import { db } from '../firebase'
import Swal from 'sweetalert2'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    overlay: false,
    drawer: false,
    images: [],
    jobs: []
  },
  mutations: {
    SET_OVERLAY: (state, payload) => state.overlay = payload,
    SET_DRAWER: (state, payload) => state.drawer = payload,
    SET_IMAGES: (state, val) => state.images = val,
    SET_JOBS: (state, val) => state.jobs = val
  },
  actions: {
    setImages({ commit }) {
      db.collection('gallery').onSnapshot(docs => commit('SET_IMAGES', docs.docs.map(doc => doc.data())))
    },
    setJobs({ commit }) {
      let date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      db.collection('careers').where('status', '==', true).where('expiryDate', '>=', date).onSnapshot(docs => commit('SET_JOBS', docs.docs.map(doc => doc.data())))
    },
    async sendMessage({ commit }, { form }) {
      commit('SET_OVERLAY', true)
      return await new Promise((resolve, reject) => {
        form['createdAt'] = new Date().getTime()
        form['updatedAt'] = new Date().getTime()
        form['status'] = true
        db.collection('messages').add(form).then( () => {
          Swal.fire('Received!', 'We have received your message. We\'ll get back to you as soon as possible. Thank you!', 'success')
          resolve()
        }).catch( e => {
          Swal.fire('Error!', e.message, 'error')
          reject(e)
        })
      }).finally( () => commit('SET_OVERLAY', false))
    }
  }
})
