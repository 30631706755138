<template>
  <v-app>
    <v-overlay :value="overlay" v-if="overlay === true" absolute z-index="203">
      <v-progress-circular indeterminate size="50" />
    </v-overlay>
    <Navbar />
    <Sidebar />
    <v-main>
      <router-view :key="$route.path" />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Navbar from './components/Navbar.vue'
import Sidebar from './components/Sidebar.vue'
import Footer from './components/Footer.vue'
export default {
  name: 'App',
  components: { 
    Navbar, 
    Sidebar,
    Footer 
  },
  computed: {
    overlay() { return this.$store.state.overlay }
  },
};
</script>

<style lang="scss">
$border-color: rgba($color: #000000, $alpha: .25); 
::-webkit-scrollbar { width: 0; }
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html { scroll-behavior: smooth; }
.v-application--wrap { background-color: transparent !important; }
.v-toolbar__content { 
  margin: 0 auto;
  max-width: 1250px;
  padding: 0 12px !important; 
  .v-btn.v-btn--icon:last-child { margin-right: 0 !important; }
}
/* Container */
.container {
  max-width: 1250px !important;
  margin-right: auto !important;
  margin-left: auto !important;
  padding: 12px !important;
}
p { 
  margin-bottom: 0 !important;
  font-size: 13px;
  line-height: 1.4;
  letter-spacing: .5px;
}
/* Button */
.v-btn { text-transform: capitalize !important; }

/* Inputs */
.v-input input, .v-input textarea { 
  font-size: 13px !important; 
  letter-spacing: .75px !important; 
}
.v-input .v-label { 
  font-size: 13px !important; 
  letter-spacing: 1px !important; 
}
.v-input__icon .v-icon.v-icon, .v-select-list .v-icon.v-icon { font-size: 16px !important; }
.v-text-field--outlined.v-input--is-focused fieldset { border: 1px solid #21a5f7 !important; }
.v-text-field--outlined.v-input--has-state fieldset { border: 1px solid #f36c27 !important; }

// image
img {
  height: 100%;
  width: 100%;
  display: block;
  object-fit: contain;
}

/* Banner */
.banner { 
  position: relative; 
  img {
    max-height: calc( 100vh - 70px);
    min-height: 40vh;
    object-fit: cover;
  }
  .banner__info {
    position: absolute; 
    height: 100%;
    width: 100%;
    background-color: rgb( 0 0 0 / 30%); 
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .text__banner {
    height: 50vh; 
    background: linear-gradient(to right, #21a5f7 0%, #b42cd6 100%); 
    .text__banner__content {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      line-height: 1;
      color: white;
    }
    @media (max-width: 860px) { height: 45vh; }
    @media (max-width: 600px) { height: 40vh; }
  }
}

// gallery images
.life__at {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15%, 1fr));  
  .life__at__img { 
    overflow: hidden; 
    img { 
      transition: ease-in-out 250ms; 
      object-fit: cover;
    }
    &:hover img { transform: scale(1.25); }
  }
  @media (max-width: 1060px) { grid-template-columns: repeat(auto-fit, minmax(20%, 1fr)); }
  @media (max-width: 860px) { grid-template-columns: repeat(auto-fit, minmax(25%, 1fr)); }  
  @media (max-width: 720px) { grid-template-columns: repeat(auto-fit, minmax(30%, 1fr)); }  
  @media (max-width: 500px) { grid-template-columns: repeat(auto-fit, minmax(50%, 1fr)); }  
}
</style>