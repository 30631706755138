<template>
  <v-navigation-drawer v-model="drawer" app mobile-breakpoint="10024" dark bottom> 
    <div class="pa-3 side__menu">
      <v-btn v-for="(menu, i) in menus" :key="i" :to="menu.to" text>
        <v-icon size="16" v-text="menu.icon" />
        <span v-text="menu.title" />
      </v-btn>
    </div>
  </v-navigation-drawer>  
</template>

<script>
export default {
  data: () => ({
    selectedItem: 1, 
  }),
  computed: {
    drawer: {
      get () { return this.$store.state.drawer },
      set (val) { this.$store.commit('SET_DRAWER', val) },
    },
  }
}
</script>

<style lang="scss">
.v-navigation-drawer {
  height: 234px !important;
  border-radius: 12px !important;
  bottom: 12px !important;
  background: linear-gradient(45deg, #21a5f7 0%, #b42cd6 100%) !important;
  .side__menu {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
    .v-btn {
      height: 70px;
      .v-btn__content {
        flex-direction: column;
        span { 
          margin-top: 6px; 
          font-weight: 300 !important;
          font-size: 13px !important;
          letter-spacing: 1.5px !important;
        }
      }
    }
  }
}
</style>