import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/lib/services/goto'

Vue.use(VueRouter)

const routes = [
  {
    path: '/', name: 'Home', component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: {
      title: 'Home - Numanchi',
      metaTags: [
        { name: 'description', content: 'Numanchi home page description' },
        { property: 'og:description', content: 'Numanchi home page description' }
      ]
    }
  },
  {
    path: '/about', name: 'About',
    meta: {
      title: 'About Us - Numanchi',
      metaTags: [
        { name: 'description', content: 'Numanchi about us page description' },
        { property: 'og:description', content: 'Numanchi about us page description' }
      ]
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/contact', name: 'Contact',
    meta: {
      title: 'Contact Us - Numanchi',
      metaTags: [
        { name: 'description', content: 'Numanchi contact us page description' },
        { property: 'og:description', content: 'Numanchi contact us page description' }
      ]
    },
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  },
  {
    path: '/services', name: 'Services',
    meta: {
      title: 'Services - Numanchi',
      metaTags: [
        { name: 'description', content: 'Numanchi services page description' },
        { property: 'og:description', content: 'Numanchi services page description' }
      ]
    },
    component: () => import(/* webpackChunkName: "services" */ '../views/Services.vue')
  },
  {
    path: '/products', name: 'Products',
    meta: {
      title: 'Products - Numanchi',
      metaTags: [
        { name: 'description', content: 'Numanchi products page description' },
        { property: 'og:description', content: 'Numanchi products page description' }
      ]
    },
    component: () => import(/* webpackChunkName: "products" */ '../views/Products.vue')
  },
  {
    path: '/gallery', name: 'Gallery',
    meta: {
      title: 'Gallery - Numanchi',
      metaTags: [
        { name: 'description', content: 'Numanchi gallery page description' },
        { property: 'og:description', content: 'Numanchi gallery page description' }
      ]
    },
    component: () => import(/* webpackChunkName: "gallery" */ '../views/Gallery.vue')
  },
  {
    path: '/career', name: 'Career',
    meta: {
      title: 'Career - Numanchi',
      metaTags: [
        { name: 'description', content: 'Numanchi career page description' },
        { property: 'og:description', content: 'Numanchi career page description' }
      ]
    },
    component: () => import(/* webpackChunkName: "career" */ '../views/Career.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0
    if (to.hash)  scrollTo = to.hash
    else if (savedPosition) scrollTo = savedPosition.y
    return goTo(scrollTo)
  },
  routes
})

export default router
