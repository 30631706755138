<template>
  <v-app-bar app color="white" height="70" elevate-on-scroll>
    <v-img max-width="60" src="../assets/logo.png" lazy-src="../assets/logo.png" alt="Nuamnchi Logo"></v-img>
    <v-spacer />
    <div class="ml-md-8 ml-lg-10 d-none d-sm-none d-md-block nav">
      <v-btn text active-class="active__nav" tile v-for="(nav, n) in menus" :key="n" :to="nav.to">
        <span v-text="nav.title" />
      </v-btn>
    </div>
    <v-app-bar-nav-icon @click.stop="setDrawer(!drawer)" class="primary d-block d-sm-block d-md-none" />
  </v-app-bar>  
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'navbar',
  computed: {
    ...mapState(['drawer']),
  },
  methods: {
    ...mapMutations({
      setDrawer: 'SET_DRAWER',
    }),
  },  
}
</script>

<style lang="scss">
.nav .theme--light.v-btn--active:hover::before, .nav .theme--light.v-btn--active::before { opacity: 0 !important; }
.nav .v-btn { height: 70px !important; }
.active__nav {
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-image: linear-gradient(to right, #21a5f7, #b42cd6) 1;
  font-weight: 500 !important;
  .v-btn__content {
    span {
      background: #21a5f7;
      background: -webkit-linear-gradient(to right, #21a5f7 0%, #b42cd6 100%);
      background: -moz-linear-gradient(to right, #21a5f7 0%, #b42cd6 100%);
      background: linear-gradient(to right, #21a5f7 0%, #b42cd6 100%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
.v-toolbar__content .v-btn.v-btn--icon.v-size--default {
  height: 40px !important;
  width: 40px !important;
  background: linear-gradient(45deg, #21a5f7 0%, #b42cd6 100%) !important;
  .v-icon {
    font-size: 20px !important;
    color: white !important;
  }
}
</style>